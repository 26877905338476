import React, {useCallback, useEffect, useState} from 'react';
import Link from "next/link";
import styled, {css, useTheme} from "styled-components";
import {Box, Flex, Text} from 'rebass/styled-components';
import {slide as Menu} from 'react-burger-menu'
import {useRouter} from 'next/router';
import {i18n, useTranslation} from "next-i18next";
import loadable from '@loadable/component'
import dynamic from "next/dynamic";
import Image from 'next/image';

const PhoneIcon = dynamic(() => import('@react-icons/all-files/fa/FaPhone').then(m => m.FaPhone), {ssr: false,});
const TelegramIcon = dynamic(() => import('@react-icons/all-files/fa/FaTelegram').then(m => m.FaTelegram), {ssr: false,});
const MailIcon = dynamic(() => import('@react-icons/all-files/fa/FaEnvelope').then(m => m.FaEnvelope), {ssr: false,});

const FloatingMenu = loadable(() => import('react-floating-button-menu').then(m => m.FloatingMenu))
const MainButton = loadable(() => import('react-floating-button-menu').then(m => m.MainButton))
const ChildButton = loadable(() => import('react-floating-button-menu').then(m => m.ChildButton))
const ToggleSwitch = loadable(() => import('./components/ToggleSwitch'))

const AppHeader = ({screenWidth: width, footerAnchorRef}) => {
  const {breakpointsNumeric, pageGutters} = useTheme();
  const {t} = useTranslation('common');
  const {push, pathname, asPath} = useRouter();

  const smMobileWidth = breakpointsNumeric[0];
  const mobileWidth = breakpointsNumeric[1];
  const laptopWidth = breakpointsNumeric[2];

  const [menuOpened, setMenuOpened] = useState(false);
  const [fabMenuOpened, setFabMenuOpened] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);

  const handleSwitchChange = useCallback((val) => {
    const lang = val ? 'ru' : 'ua';
    i18n.changeLanguage(lang)
      .then(() => {
        setTimeout(() => {
          const htmlElement = document.getElementsByTagName('html')[0];
          htmlElement.setAttribute('lang', val ? 'ru-UA' : 'uk-UA')
        }, 500);
      })
      .catch(console.error);
    push(asPath, undefined, {locale: lang})
    setCheckedValue(val);
  }, [pathname]);

  const handleStateChange = useCallback((state) => {
    setMenuOpened(state.isOpen);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpened(false);
  }, []);

  useEffect(() => {
    const {language = 'ua'} = i18n || {};
    if (language === 'ru') {
      setCheckedValue(true);
    } else {
      setCheckedValue(false);
    }
  }, []);

  useEffect(() => {
    const {language: currentLanguage} = i18n || {};
    setTimeout(() => {
      document.documentElement.lang = currentLanguage === 'ua' ? 'uk-UA' : 'ru-UA';
    }, 500)
  }, [pathname, asPath]);

  const simulateCall = useCallback((number) => {
    window.open(`tel:${number}`, '_self')
  }, []);

  const handlePhoneClick = useCallback(() => {
    simulateCall('+380962229054');
    setFabMenuOpened(false);
  }, []);

  const handleTelegramClick = useCallback(() => {
    window.open('https://t.me/carfindua', '_blank');
    setFabMenuOpened(false);
  }, []);

  const handleViberClick = useCallback(() => {
    simulateCall('+380683643801');
    setFabMenuOpened(false);
    // window.open('https://invite.viber.com/?g2=AQBQnZSAZOg83E6LzJVwJuMZonPt8oDcBKB3c5dmC%2BrbeijEecCUVm%2BNUNoChG9i', '_blank');
    // setFabMenuOpened(false);
  }, []);

  const handleMailClick = useCallback(() => {
    footerAnchorRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setFabMenuOpened(false);
  }, []);

  return (
    <>
      {width <= mobileWidth && (
        <Menu isOpen={menuOpened}
              onStateChange={handleStateChange}
              width={'fit-content'}
              pageWrapId={'header-wrapper'}
              outerContainerId={"app-wrapper"}
        >
          <Box
            sx={{
              'span': {
                position: 'initial !important'
              }
            }}
          >
            <Link rel='preload' href='/' onClick={closeMenu}>
              <CarFindLogo
                layout='fill'
                title={t('logoTitle')}
                src="/static-web/images/logo-white-min.png"
                alt={t('mainPageHeader')}
              />
            </Link>
          </Box>
          <LinksWrapper isMobile>
            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/about'>
                {t('menuAbout')}
              </Link>
            </StyledLink>

            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/services'>
                {t('menuServices')}
              </Link>
            </StyledLink>

            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/catalogue'>
                {t('menuCatalogue')}
              </Link>
            </StyledLink>

            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/electric-cars'>
                {t('menuElectricCars')}
              </Link>
            </StyledLink>

            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/calculator'>
                {t('menuCalculator')}
              </Link>
            </StyledLink>

            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/contacts'>
                {t('menuContacts')}
              </Link>
            </StyledLink>

            <StyledLink
              onClick={closeMenu}
            >
              <Link rel='preload' href='/carfind-live'>
                Carfind Live
              </Link>
            </StyledLink>
          </LinksWrapper>

          <LanguageSelector isMobile>
            <ToggleSwitch
              id="switch"
              onChange={handleSwitchChange}
              checked={checkedValue}
            />
          </LanguageSelector>
        </Menu>
      )}
      <Wrapper
        id='header-wrapper'
        py={4}
        px={[3, 6, 6, pageGutters[4], 6]}
      >
        <Flex
          sx={{
            alignSelf: width <= laptopWidth ? 'start' : 'end',
            alignItems: 'center',
            fontFamily: 'primary',
            color: 'text.primary',
            mb: 2,
            px: width <= laptopWidth ? 3 : 2,
            width: '100%',
            justifyContent: width <= laptopWidth ? 'space-between' : 'end'
          }}
        >
          {width <= laptopWidth && (
            <Box
              sx={{
                p: width <= laptopWidth ? 2 : 0,
                py: width <= mobileWidth ? 2 : 0,
                px: width <= mobileWidth ? 0 : 2,
                'span': {
                  position: 'initial !important'
                }
              }}
            >
              <Link href='/'>
                <CarFindLogo
                  layout='fill'
                  alt='Carfind logo'
                  src="/static-web/images/logo-white-min.png"/>
              </Link>
            </Box>
          )}

          {!(width <= mobileWidth) && (
            <Flex
              alignItems='center'
              sx={{
                ml: 'auto'
              }}
            >
              <Flex
                flexDirection='column'
                alignSelf='end'
                fontSize={14}
                sx={{
                  mt: 'auto',
                  mb: 'auto',
                }}
              >
                <Box
                  sx={{
                    '&:hover': {
                      cursor: 'pointer'
                    },
                  }}
                  onClick={() => {
                    simulateCall('+380962156759')
                  }}
                >
                  +38 (096) 215 67 59
                </Box>
                {/*<Box*/}
                {/*  sx={{*/}
                {/*    '&:hover': {*/}
                {/*      cursor: 'pointer'*/}
                {/*    }*/}
                {/*  }}*/}
                {/*  onClick={() => {*/}
                {/*    simulateCall('+380992229054')*/}
                {/*  }}*/}
                {/*>*/}
                {/*  +38 (099) 222-90-54*/}
                {/*</Box>*/}
              </Flex>
              <Box
                sx={{
                  ml: 1,
                  borderRadius: '50%',
                  border: '1px solid #fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 36,
                  height: 36,
                  '&:hover': {
                    cursor: 'pointer'
                  }
                }}
              >
                <PhoneIcon/>
              </Box>
            </Flex>
          )}
        </Flex>

        <Flex
          sx={{
            alignItems: 'center',
            px: width <= laptopWidth ? 2 : 1
          }}
        >
          {!(width <= laptopWidth) && (
            <Box
              p={2}
              sx={{
                'span': {
                  position: 'initial !important'
                }
              }}
            >
              <Link rel='preload' href='/'>
                <CarFindLogo
                  layout='fill'
                  alt='Carfind logo white'
                  src="/static-web/images/logo-white-min.png"/>
              </Link>
            </Box>
          )}

          {!(width <= mobileWidth) && (
            <>
              <LinksWrapper isLaptop={width <= laptopWidth}>
                <StyledLink>
                  <Link rel='preload' href='/about'>
                    {t('menuAbout')}
                  </Link>
                </StyledLink>
                <StyledLink>
                  <Link rel='preload' href='/services'>
                    {t('menuServices')}
                  </Link>
                </StyledLink>
                <StyledLink>
                  <Link rel='preload' href='/catalogue'>
                    {t('menuCatalogue')}
                  </Link>
                </StyledLink>
                <StyledLink>
                  <Link rel='preload' href='/electric-cars'>
                    {t('menuElectricCars')}
                  </Link>
                </StyledLink>
                <StyledLink>
                  <Link rel='preload' href='/calculator'>
                    {t('menuCalculator')}
                  </Link>
                </StyledLink>
                <StyledLink>
                  <Link rel='preload' href='/contacts'>
                    {t('menuContacts')}
                  </Link>
                </StyledLink>
                <StyledLink>
                  <Link rel='preload' href='/carfind-live'>
                    Carfind Live
                  </Link>
                </StyledLink>
              </LinksWrapper>

              <LanguageSelector>
                <ToggleSwitch
                  id="switch"
                  onChange={handleSwitchChange}
                  checked={checkedValue}
                />
              </LanguageSelector>
            </>
          )}
        </Flex>

        <FabWrapper
          rightSide={pathname === '/services' || pathname === '/calculator'}
          calculator={pathname === '/calculator'}
        >
          <FloatingMenu
            slideSpeed={500}
            direction="up"
            spacing={8}
            isOpen={fabMenuOpened}
          >
            <MainButton
              background='#fff'
              iconResting={<FabButtonLabel>{t('headerFabLabel')}</FabButtonLabel>}
              iconActive={<FabButtonLabel>{t('headerFabLabel')}</FabButtonLabel>}
              backgroundColor="black"
              onClick={() => setFabMenuOpened(!fabMenuOpened)}
              size={100}
            />
            <ChildButton
              icon={<MailIcon size={20} />}
              backgroundColor="#fff"
              background="#fff"
              size={60}
              onClick={handleMailClick}
            />
            <ChildButton
              icon={<PhoneIcon size={20} />}
              backgroundColor="#fff"
              background="#fff"
              size={60}
              onClick={handleViberClick}
            />
            <ChildButton
              icon={<TelegramIcon size={20} />}
              backgroundColor="#fff"
              background="#fff"
              size={60}
              onClick={handleTelegramClick}
            />
          </FloatingMenu>
        </FabWrapper>
      </Wrapper>
    </>
  )
};

const CarFindLogo = styled(Image)`
  position: initial !important;
  width: 165px !important;
  min-width: 165px !important;
  min-height: 37px !important;
  height: 37px !important;
  margin: 0 !important;
  &:hover {
    cursor: pointer
  }
`

const FabButtonLabel = styled(Text).attrs(() => ({
  fontFamily: 'primary'
}))`
  text-align: center;
  color: #000;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 11px;
  }
`

const FabWrapper = styled(Flex)`
  position: absolute;
  left: 90%;
  top: 150px;
  width: fit-content;
  z-index: 999;
  
  div {
    transform: rotate(0deg) !important;
  }
  
  svg {
    path {
      fill: #000;
    }
  }
  
  .rtf--ab__c {
    button {
      background: #fff;
    }
  }
  
  @media (max-width: 1600px) {
    left: 80%
  }

  @media (max-width: 1200px) {
    left: 87%
  }

  @media (max-width: 900px) {
    top: 35px;
    left: 80%
  }

  @media (max-width: 600px) {
    top: 55px;
    left: 80%;
    ul a {
      width: 75px !important;
      height: 75px !important;
    }

    ul li {
      width: 50px !important;
      height: 50px !important;
    }
  }

  @media (max-width: 400px) {
    top: 55px;
    left: 75%
  }

  ${({ rightSide }) => rightSide && css`
    right: initial !important;
    left: 65px !important;
    @media (max-width: 600px) {
      top: 110px !important;
      left: 75% !important;
    }
    ${({ calculator }) => calculator && css`
      @media (max-width: 600px) {
        top: -35px !important;
      }
    `}
  `}
`

const Wrapper = styled(Flex)`
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  z-index: 101;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
`

const LanguageSelector = styled(Box)`
  position: relative;
  padding: 5px;
  color: ${({theme}) => theme.colors.text.primary};
  ${({isMobile}) => isMobile && css`
    padding-left: 0 !important;
  `}
`

const StyledLink = styled(Box)`
  font-family: ${({theme}) => theme.fonts.primary};
  font-size: 12px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.text.primary};
  padding: ${({theme}) => `${theme.space[1]}px`};
  margin: ${({theme}) => `0 ${theme.space[3]}px`};
  border-radius: 18px;
  white-space: nowrap;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &:hover {
    color: ${({theme}) => theme.colors.text.secondary};
    background-color: rgb(185, 183, 180);
  }

  @media (max-width: ${({theme}) => theme.breakpoints[2]}) {
    font-size: 14px;
    margin: ${({theme}) => `0 ${theme.space[2]}px`};
  }

  @media (max-width: 1400px) {
    font-size: 12px;
  }

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 1600px) {
    margin: 0 8px;
  }

  @media (max-width: 900px) {
    margin: ${({theme}) => `0 ${theme.space[3]}px`};
  }
`

const LinksWrapper = styled(Flex)`
  margin: ${({isLaptop}) => isLaptop ? '0 auto 0 0' : '0 auto'};
  ${({isMobile}) => isMobile && css`
    display: flex !important;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 25px;

    ${StyledLink} {
      margin-left: -5px !important;
    }
  `}
`

export default AppHeader;
